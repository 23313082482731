var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"cec4abc173bd3a36ce806353053754839eb443e5"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const sentryDsn = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const environment = process.env.NEXT_PUBLIC_ENVIRONMENT || 'development';
// eslint-disable-next-line no-process-env
const release = process.env.RENDER_GIT_COMMIT;

Sentry.init({
  dsn: sentryDsn || 'https://39bddc8f51524826ace774c32ff22cda@o242872.ingest.sentry.io/5422682',
  tracesSampleRate: 1.0,
  environment,
  release,
});
